$color-coral: #FF6F61;
#landing-maincontent {
	margin-bottom: 60px;
	a {
		text-decoration: underline;
		color: $color-coral;
	}
}
#site-main {
	padding: 0;
}
.inset {
	line-height: 30px;
	font-size: 20px;
	font-weight: 300;
	padding: 0px 120px;
	@media screen and (max-width: 1500px) and (min-width: 1025px) {
		padding: 0 30px;
	}
	h2 {
		margin-bottom: 30px;
		margin-top: 40px;
		font-size: 26px;
	}
}

#siteheader-top-logo {
	text-align: center;
	.icon {
		font-size: 95px;
	}
}
#siteheader-isonline {
	.icon {
		font-size: 82px;
		width: 82px;
		float: right;
	}
}
h1 {
	font-size: 32px;
	margin-top: 60px;
	margin-bottom: 30px;
	height: auto;
	font-weight: normal;
	line-height: 40px;
}
#landing-whatsapp {
	display: flex;
	margin-top: 60px;
	font-size: 20px;
	font-weight: 300;
	line-height: 30px;
	margin-bottom: 60px;
	img {
		width: 60%;
	}
	> div {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 37%;
		padding-left: 3%;
		.icon {
			color: #25D366;
			margin-bottom: 12px;
			font-size: 115px;
		}
		a {
			max-width: 186px;
			margin-top: 20px;
			display: inline-block;
			padding: 12px 75px;
			font-size: 18px;
			font-weight: normal;
		}
	}
}
#landing-question {
	display: flex;
	justify-content: space-around;
	margin-top: 120px;
	margin-bottom: 120px;
	.handwriting {
		margin-right: 0px;
		line-height: 65px;
		font-size: 48px;
		flex-basis: 44%;
		text-align:center;
		line-height: 58px;
	}
	img {
		margin-right: 80px;
		max-width: 350px;
	}
	a.button {
		width: 100%;
		padding: 12px 0px;
		display: inline-block;
		text-align: center;
		font-size: 18px;
    		font-weight: normal;
	}
	ul.dropdown-menu {
		width: calc(100% - 18px);
	}
	.dropdown {
		width: 450px;
		margin-bottom: 20px;
		padding: 13px 14px 9px 14px;
		margin-left: 0;
		font-size: 16px;
		background-color: #F1F1F1;
		.icon {
			position: absolute;
			right: 10px;
		}
	}
	.pageheader-with-menu {
		margin-top: 6px;
		margin-bottom: 0;
	}
}
#landing-shootings {
	background-color: #778997;
	padding: 20px 120px 60px 120px;
	color: white;
	text-align: center;
	#landing-shootings-photos {
		margin-top: 40px;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 15px;
		a {
			display: inline-block;
			line-height: 0px;
			img {
				width: 100%;
				border-radius: 5px;
				box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
			}
		}
	}
	a.fullwidth {
		color: white;
		margin-top: 60px;
	}
}
#landing-bottompics {
	margin-top: 60px;
	margin-bottom: 60px;
	display: flex;
	justify-content: space-between;
	div:nth-child(n+5) {
		display: none;
	}
	a.modal-trigger {
		display: inline-block;
		position: relative;
		&:nth-child(6) {
			div {
				filter: brightness(70%) blur(2px);
			}
			&:after {
				display: block;
				content: "Mehr Bilder »";
				font-size: 16px;
				color: #ffffff;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -42px;
    			margin-top: -16px;
			}
		}
		img {
			width: 100%;
		}
	}
}
#landing-3columns {
	margin-top: 40px;
	h2 {
		color: $color-coral;
		font-size: 26px;
		font-weight: normal;
		margin-bottom: 15px;
		margin-top: 0;
	}
	font-size: 18px;
	line-height: 28px;
	display: flex;
	> div {
		padding: 20px;
		&:nth-child(1) {
			padding-left: 0;
		}
		&:nth-child(3) {
			padding-right: 0;
		}
	}
}
#landing-wrapper {
	display: flex;
	margin-top: 40px;
	position: relative;
	justify-content: space-between;

	#landing-left, .lSSlideOuter {
		width: 65%;
	}
	#landing-right {
		width: 32%;
		background-color: #F5F6FA;
		display: flex;
		flex-direction: column;
		#landing-right-text {
			position: absolute;
			bottom: 0;
			font-size: 18px;
			line-height: 24px;
			font-weight: 300;
			padding: 350px 26px 30px 26px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-grow: 1;
			background-image: linear-gradient(180deg, rgba(245,246,250,0) 0%, rgba(245,246,250,1) 70%);
			a {
				margin-top: 20px;
				display: inline-block;
				padding: 8px 25px;
				font-size: 16px;
				font-weight: normal;
				border-radius: 99px;
			}
		}
	}
	img {
		width: 100%;
		height: auto;
		vertical-align: middle;
	}
}
#landing-desktop {
	#landing-wall {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
		grid-column-gap: 3px;
		grid-row-gap: 3px;
		> div {
			position: relative;
		}
		img {
			vertical-align: middle;
			height: 100%;
			width: 100%;
		}
		.hovertext {
			text-transform: uppercase;
			font-size: 28px;
			@media screen and (max-width: 1400px) {
				font-size: 21px;
			}
			position: absolute;
			cursor: pointer;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.4);
			color: white;
			font-weight: 300;
			> a {
				position: absolute;
				bottom: 50px;
				width: 100%;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				flex-direction: column;
				color: white;
			}
			.bold {
				font-size: 40px;
				font-weight: 600;
			}
		}
		#landing-wall-img-1 {
			grid-column: 1 / span 2;
  			grid-row: 1 / span 4;
		}
		#landing-wall-img-2 {
			grid-column: 1 / span 2;
  			grid-row: 5 / span 2;
		}
		#landing-wall-img-3 {
			grid-column: 3 / span 2;
  			grid-row: 1 / span 2;
		}
		#landing-wall-img-4 {
			grid-column: 3 / span 2;
  			grid-row: 3 / span 2;
		}
		#landing-wall-img-5 {
			grid-column: 3 / span 2;
			grid-row: 5 / span 2;
			.bold {
				color: #ACE81A;
			}
		}
		#landing-wall-img-6 {
			grid-column: 5 / span 1;
  			grid-row: 1 / span 1;
		}
		#landing-wall-img-7 {
			grid-column: 5 / span 1;
  			grid-row: 2 / span 1;
		}
		#landing-wall-img-8 {
			grid-column: 6 / span 1;
  			grid-row: 1 / span 1;
		}
		#landing-wall-img-9 {
			grid-column: 6 / span 1;
  			grid-row: 2 / span 1;
		}
		#landing-wall-img-10 {
			grid-column: 5 / span 2;
  			grid-row: 3 / span 2;
		}
		#landing-wall-img-11 {
			grid-column: 5 / span 2;
  			grid-row: 5 / span 2;
		}
		#landing-wall-img-12 {
			grid-column: 7 / span 2;
  			grid-row: 1 / span 6;
		}
	}
}
#siteheader-top {
	transition: height 0.3s ease-in-out;
	height: 160px;
	overflow: hidden;
}

#site-wrapper.sticky {
	#siteheader-logo {
		opacity: 1;
	}
}

@media screen and (max-width: 1024px) {
	#landing-maincontent {margin-bottom: 40px;}
	#siteheader-top {
		display: none;
	}
	#landing-wrapper {
		img, picture, .lSSlideOuter {
			width: 100%;
		}
	}
	#landing-bottompics {
		display: block;
		margin-top: 20px;
		margin-bottom: 40px;
		a.modal-trigger {
			&:nth-child(6) {
				&:after {
					font-size: 14px;
					margin-left: -52px;
					margin-top: -12px;
					@media screen and (max-width: 680px) {
						font-size: 12px;
						margin-left: -62px;
						margin-top: -10px;
					}
					@media screen and (max-width: 580px) {
						font-size: 14px;
						margin-left: -43px;
						margin-top: -72px;
					}
					@media screen and (max-width: 400px) {
						content: "Mehr Bilder";
						font-size: 12px;
						margin-left: -33px;
						margin-top: -54px;
					}
				}
			}
		}
	}
	#landing-shootings {
		padding: 20px;
		line-height: 24px;
		font-size: 16px;
		h2 {
			font-size: 20px;
			margin-top: 0;
			margin-bottom: 20px;
		}
		#landing-shootings-photos {
			margin-top: 15px;
			grid-gap: 10px;
		}
		a.fullwidth {
			margin-top: 20px;
			font-size: 18px;
   			font-weight: normal;
		}
	}
	#landing-question {
		margin-top: 40px;
		margin-bottom: 40px;
		flex-direction: column;
		align-items: center;
		.handwriting {
			width: 65%;
			text-align: center;
			margin-bottom: 40px;
			@media screen and (max-width: 500px) {
				line-height: 36px;
				font-size: 31px;
				width: 90%;
			}
		}
		> div {
			width: 100%;
			div.pageheader-with-menu {
				margin-bottom: 0;
			}
			.dropdown {
				width: 100%;
			}
			ul.dropdown-menu {
				top: 55px;
				margin-bottom: 30px;
			}
			a.button {
				padding: 14px 0px;
			}
		}
	}
	h1 {
		font-size: 20px;
		margin-top: 20px;
		margin-bottom: 15px;
		line-height: 28px;
	}
	a.fullwidth {
		font-size: 20px;
		margin-top: 25px;
	}
	#landing-whatsapp {
		margin-top: 25px;
		position: relative;
		flex-direction: column;
		align-items: center;
		img {
			width: 100%;
		}
		> div {
			margin: 0px 20px;
			width: calc(100% - 40px);
			padding-left: 0;
			padding-bottom: 20px;
			border-bottom: 1px solid #f1f1f1;
			.button {
				margin-top: 10px;
				padding: 12px 63px;
				font-size: 18px;
			}
		}

	}
	#landing-whatsapp + .inset {
		h2 {
			font-size: 18px;
			color: $color-coral;
			margin-bottom: 15px;
		}
	}
	.inset {
		font-size: 16px;
		padding: 0px 20px;
		line-height: 24px;
		h2 {
			font-size: 22px;
			margin-bottom: 20px;
		}
	}
	#landing-wrapper {
		margin-top: 0;
		flex-direction: column;
		#landing-left,
		#landing-right {
			width: auto;
		}
		#landing-right img {
			display: none;
		}
		#landing-right {
			#landing-right-text {
				bottom: 0;
				position: relative;
				padding: 20px;
				a {
					margin-top: 10px;
					padding: 12px 63px;
					font-size: 18px;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	#landing-3columns {
		font-size: 16px;
		line-height: 24px;
		h2 {
			font-size: 20px;
		}
	}
	#landing-wrapper #landing-right #landing-right-text {
		font-size: 16px;
	}
}

@media screen and (max-width: 1024px) {
	#landing-newvideos {
		a.fullwidth {
			margin-top: 0;
			font-size: 16px;
			font-weight: 400;
			text-decoration: underline;
		}
	}
}

@media screen and (max-width: 500px) {
	#landing-shootings #landing-shootings-photos {
		grid-template-columns: 1fr 1fr;
	}
}
